import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs, { isDayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useSearchParams } from "next/navigation";

import { QueryKeys } from "@/assets/ts/queryClient";

import { fetchCardinalBenefits, fetchCreateEventLog, fetchEventLogsCount, fetchMemberInfo, fetchSearchEvent, fetchSearchEventLog, fetchUpdateEventLog } from "./fetch/fetchEvent";
import { fetchPassersList } from "./fetch/fetchPassersList";

import {
  TCreateEventLogInput,
  TEventCardinalBenefits,
  TEventCardinalBenefitsOptions,
  TEventCardinals,
  TFreepassList,
  TSearchEventLog,
  TSearchEventsLogOptions,
  TSearchEventsOptions,
  TUpdateEventLogInput
} from "@/interface/event";
import { ProductContent } from "@/interface/lecture";

import CryptoAES from "@/lib/CryptoAES";
import useUserStore from "@/store/user";
import { useCallback, useEffect, useState } from "react";


dayjs.extend(isBetween);

// 신규회원 유무 조회
export const useGetMemberInfo = (memberId: string, regDate: string) => {
  return useQuery(
    [QueryKeys.GET_MEMBER_INFO, memberId],
    () => fetchMemberInfo({ memberId, regDate }),
    { enabled: !!memberId }
  )
}

// 이벤트 정보 가져오기
export const useSearchEvent = (eventCode: string, filter?: any, options?: TSearchEventsOptions) => {
  return useQuery(
    [QueryKeys.GET_SEARCH_EVENT, eventCode, filter],
    () => fetchSearchEvent(eventCode, filter),
    options
  );
}

// 이벤트 로그 가져오기
export const useSearchEventLog = (eventCode: string, filter?: any, _sort?:any, options?: TSearchEventsLogOptions) => {
  return useQuery(
    [QueryKeys.GET_SEARCH_EVENT_CODE, eventCode, filter],
    () => fetchSearchEventLog(eventCode, filter, _sort),
    options
  );
}

// 이벤트 참여 이력 생성
export const useCreateEventLog = () => {
  return useMutation((input: TCreateEventLogInput) => fetchCreateEventLog(input));
}

// 이벤트 참여 이력 업데이트
export const useUpdateEventLog = () => {
  return useMutation((input: TUpdateEventLogInput) => fetchUpdateEventLog(input));
}

export const useEventUtils = () => {
  const today = dayjs();
  const nowDate = useSearchParams().get("now_date") || '';

  // 이벤트 참여이력 확인
  async function checkJoinEvent (eventCode: string , key: string, memberIdx: number) {
    const logsCount = await fetchEventLogsCount(eventCode, {
      key: { eq: key },
      memberIdx: { eq: memberIdx },
      _deleted: { ne: true },
    });

    return (logsCount || 0) > 0;
  }

  // 기수 찾기
  function findCardinal(eventCardinals: TEventCardinals[]) {
    return eventCardinals?.filter(cardinal => {
      const diffDate = nowDate !== '' ? dayjs(nowDate) : today;

      const beginDate = diffDate.isAfter(cardinal.cardinalBeginDate);
      const endDate = diffDate.isBefore(cardinal.cardinalEndDate);

      return beginDate && endDate;
    })[0] || { cardinalBeginDate: "", cardinalEndDate: "", cardinalNumber: 0 };
  }

  // 개인정보 암호화
  function encryptUserInfo(userInfo: string) {
    const crypto = new CryptoAES("EVENT");
    const token = crypto.encryptToken(userInfo);

    return token;
  }

  // 개인정보 복호화
  function decryptUserInfo(userInfo: string) {
    const crypto = new CryptoAES("EVENT");
    const token = crypto.decryptToken(userInfo);

    return token;
  }

  // 강의 리스트 팝업
  // fetchLectureDetail() params: productIdx 배열 만들기
  function getProductIdxs(freepassLists: TFreepassList[]) {
    return freepassLists
      .map((item) => {
        let productIdxs: string[] = [];
        item.categoryItems.map((category) => {
          productIdxs = [...productIdxs, ...category.items];
        });
        return productIdxs;
      })
      .flat();
  }

  // 강의리스트 정렬
  function sortFreePassLists(data: ProductContent[]) {
    const map = new Map();

    data.forEach((d) => {
      const categoryName = d.ch_lectures.category_item.CategoryItemName;

      if (map.has(categoryName)) {
        map.set(categoryName, [...map.get(categoryName), d.ch_lectures.lec_idx]);
      } else {
        map.set(categoryName, [d.ch_lectures.lec_idx]);
      }
    });

    return {
      categoryName: data[0].ProductIdx,
      categoryItems: Object.fromEntries(map),
    };
  }

  // 마감일 텍스트 생성
  const getDeadlineText = (endDate: string) => {
    const dayDiff = dayjs(endDate).diff(dayjs(), "days");

    switch (dayDiff) {
      case 0:
        return "오늘마감";
      case 1:
        return "마감 하루 전";
      case 2:
      case 3:
        return `마감 ${dayDiff}일 전`;
      default:
        return "진행중";
    }
  };

  const checkFloatingBannerTime = (endDate: string) => {
    if (today.isBefore(dayjs(endDate))) {
      return true;
    } else {
      return false;
    }
  }

  const appointmentTime = (targetDate: string, endDate = "2999-12-31 23:59:59") => {
    const now_date = nowDate ? dayjs(nowDate as string) : today;

    // true: 에약 전, false: 예약 후
    return !dayjs(now_date).isBetween(dayjs(targetDate), dayjs(endDate));
  };

  /**
   * ## 기수별 상품 헤택
   * @param cardinals 이벤트 기수 정보
   * @param cardinalsResult 이벤트 기수 결과 정보
   * @return 헤택 상품
   */
  const getCardinalBenefits = (cardinals: TEventCardinals[], cardinalsResult?: TEventCardinalBenefits) => {
    if (!cardinalsResult) return "";

    if (Number(cardinalsResult.Lastbenefit) === 0) return cardinalsResult.benefitItems[0]?.benefitName || "";
    
    const currentCardinal = findCardinal(cardinals); // 현재 기수
    // 마지막 혜택이 지급된 기수
    const lastBenefitCardinal =
      Array.isArray(cardinalsResult.benefitCardinalResults) ?
        Math.max(...cardinalsResult.benefitCardinalResults?.map((item) => item.cardinalNumber)) :
        currentCardinal.cardinalNumber;

    let cardinalCount = 0;
    for (const { cardinalNumber } of cardinals) {
      // 현재 기수 이전이면서 마지막 혜택이 지급된 기수 이후인 경우
      if (cardinalNumber > lastBenefitCardinal && cardinalNumber <= currentCardinal.cardinalNumber) {
        cardinalCount++;
      }
    }

    const currentBenefit = ((Number(cardinalsResult.Lastbenefit) % cardinalsResult.benefitItems.length) + cardinalCount - 1) % cardinalsResult.benefitItems.length;
    return cardinalsResult.benefitItems[currentBenefit]?.benefitName || "";
  }

  return {
    checkJoinEvent,
    findCardinal,
    encryptUserInfo,
    decryptUserInfo,
    getProductIdxs,
    sortFreePassLists,
    getDeadlineText,
    checkFloatingBannerTime,
    appointmentTime,
    getCardinalBenefits,
  };
}


// 합격자 명단 가져오기
export const usePassersList = () => {
  return useQuery([QueryKeys.GET_PASSERS_LIST], () => fetchPassersList());
};

// 선착순(매일) 이벤트
export const useFirstComeEvent = (
  eventCode: string,
  key: string,
  isDaily = true
) => {
  const nowDate = useSearchParams().get("now_date") || "";

  const { mutate } = useCreateEventLog();
  const user = useUserStore((state) => state.user);
  const { encryptUserInfo, checkJoinEvent } = useEventUtils();
  const queryClient = useQueryClient();

  const { data: logCount } = useEventLogsCount(eventCode, {
    key: { eq: key },
    ...(isDaily && {
      createdAt: {
        gte:
          nowDate == ""
            ? dayjs().startOf("day")
            : dayjs(nowDate).startOf("day"),
        lt:
          nowDate == ""
            ? dayjs().add(1, "day").startOf("day")
            : dayjs(nowDate).add(1, "day").startOf("day"),
      },
    }),
  });

  const createEventLog = useCallback(
    async (
      value: any,
      onJoinEvent?: () => void,
      allowDuplicate?: boolean // 중복 참여 가능 여부
    ) => {
      if (!user?.idx) {
        return;
      }

      const isJoint = allowDuplicate
        ? false
        : await checkJoinEvent(eventCode, key, user.idx);
      if (isJoint) {
        onJoinEvent?.();
        return;
      }

      mutate(
        {
          eventCode,
          key,
          value: JSON.stringify({
            ...value,
            경로: "PC",
          }),
          userInfo: encryptUserInfo(
            JSON.stringify({
              name: user?.name,
              tel: user?.tel,
              email: user?.email,
            })
          ),
          memberId: user?.id || "",
          memberIdx: user?.idx || -1,
        },
        {
          onSuccess: () => {
            queryClient.setQueryData(
              [QueryKeys.GET_EVENT_LOGS_COUNT, eventCode],
              (prev: number | undefined) => (prev || 0) + 1
            );
            onJoinEvent?.();
          },
          onError: (err) => {
            alert("잠시 후에 다시 시도해주세요.");
            console.error(err);
            location.reload();
          },
        }
      );
    },
    [user, eventCode, queryClient, key]
  );

  return {
    createEventLog,
    count: logCount || 0,
  };
};

export const useCardinalBenefits = (eventCode: string, options?: TEventCardinalBenefitsOptions) => {
  return useQuery(
    [QueryKeys.GET_CARDINAL_BENEFITS, eventCode],
    () => fetchCardinalBenefits(eventCode),
    options
  );
};

export const useEventLogsCount = (eventCode: string, filter?: any) => {
  return useQuery([QueryKeys.GET_EVENT_LOGS_COUNT, eventCode], () =>
    fetchEventLogsCount(eventCode, filter)
  );
};
