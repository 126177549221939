import { useQuery } from "react-query";

import { QueryKeys } from "@/assets/ts/queryClient";

import {
  fetchAcademyLecture,
  fetchGetTeacher,
  fetchListTeacher,
  fetchSearchTeacher,
  fetchTeacherDetail
} from "./fetch/fetchTeacher";

import { TGetTeacher, TSearchTeacher, TSearchTeachers } from "@/interface/teacher";

export const useListTeacher = (initialData?: TSearchTeacher[]) => {
  return useQuery(
    [QueryKeys.LIST_TEACHER],
    fetchListTeacher,
    { initialData }
  );
}

export const useGetTeacher = (_teacherIdx: string, initialData?: TGetTeacher) => {
  return useQuery(
    [QueryKeys.GET_TEACHER, _teacherIdx],
    () => fetchGetTeacher(_teacherIdx),
    { initialData }
  )
}

export const useSearchTeacher = (_teacherIdx: string) => {
  return useQuery(
    [QueryKeys.GET_TEACHER, _teacherIdx],
    () => fetchSearchTeacher(_teacherIdx),
  )
}


export const useGetTeacherDetail = (_memberIdx: string) => {
  return useQuery(
    [QueryKeys.GET_TEACHER_DETAIL, _memberIdx],
    () => fetchTeacherDetail(_memberIdx),
    { enabled: false }
  )
}

export const useGetAcademyLecture = (_teacherName: string) => {
  return useQuery(
    [QueryKeys.GET_ACADEMY_LECTURE, _teacherName],
    () => fetchAcademyLecture(_teacherName),
  );
}