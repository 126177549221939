"use client";

import Link from "next/link";
import StrictImage from "../image/StrictImage";
import { TBannerItem } from "@/interface/banner";

export default function TopBanner({ data }: { data?: TBannerItem }) {
  if (!data) return null;

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: data.backgroundColor || "#fff",
      }}
    >
      <Link
        href={data.linkURL || ""}
        target={data.bannerTargetCode || "_blank"}
      >
        <StrictImage
          src={data.viewImageURL || ""}
          alt={data.title || "해커스 감정평가사"}
          style={{ display: "block", margin: "0 auto" }}
          width={1100}
          height={60}
        />
      </Link>
    </div>
  );
}
