"use client";

import styles from "./recommendLecture.module.scss";
import commonStyle from "./main.module.scss";
import Slider, { LazyLoadTypes, Settings } from "react-slick";
import StrictImage from "@/components/image/StrictImage";
import Link from "next/link";
import { useLectureDetail } from "@/hook/useLecture";
import { TBannerItem } from "@/interface/banner";
import { useMemo } from "react";

type TProps = {
  banner: TBannerItem[];
}

export default function RecommendLecture({ banner }: TProps) {
  const productIdx = useMemo(() => banner.reduce((acc, curr) => [...acc, curr.productIdx], [] as number[]), [banner]);

  const { data } = useLectureDetail({ productIdx });

  const settings: Settings = {
    dots: false,
    arrows: true,
    lazyLoad: "ondemand" as LazyLoadTypes,
    speed: 800,
    slidesToShow: banner.length > 3 ? 3 : banner.length,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
  };

  return (
    <>
      {banner.length !== 0 && (
        <div className={styles.recommendLecture}>
          <div className="inner">
            <div className={commonStyle.secTitle}>
              <p>
                해커스 감정평가사 <b>추천강의</b>
              </p>
            </div>
            <Slider {...settings}>
              {banner.map((i, index: number) => {
                return (
                  <Link
                    href={{ pathname: `/lecture/${i.productIdx}` }}
                    key={`${i.productIdx}_${index}`}
                    className={styles.slideItem}
                  >
                    <div className={styles.thumbWrap}>
                      <StrictImage
                        className={styles.thumbImg}
                        src={i.viewImageURL}
                        width={342}
                        height={192}
                        alt={i.title}
                      />
                    </div>
                    {
                      data?.data && data.data.filter(d => Number(d.ProductIdx) === i.productIdx).map(d => {
                        return(
                        <div key={`${d.ProductIdx}-${d.ProductName}`}>
                          <p className={styles.lecTitle}>{d.ProductName}</p>
                          <p className={styles.lecDetail}>
                            <span className={styles.teacherName}>
                              교수님 : {d._teacherNameList}
                            </span>
                            <span>수강기간 : {d.product_learning_info?.PeriodValue || '0'}</span>
                            <span>강의 수 : {d._lectureCount}</span>
                          </p>
                        </div>
                      )})
                    }
                  </Link>
                );
              })}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}
