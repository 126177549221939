import { AxiosResponse } from "axios";

import { graphqlFetcher } from "@/assets/ts/queryClient";

import { getTeacher, searchTeachers } from "@/graphql/customQueries";

import {
  TTeacherDetail,
  TSearchTeachers,
  TGetTeacher,
  TAcademyLecture,
  TSearchTeacher
} from "@/interface/teacher";
import { APIReturnType } from "@/interface/api";

import ApiClient from "@/lib/ApiClient";

export const fetchListTeacher = async () => {
  const result = await graphqlFetcher<{ searchTeachers: TSearchTeachers }>(
    searchTeachers,
    {
      filter: {
        _deleted: { ne: true },
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
      },
      sort: [{ field: "sortValue", direction: "asc" }]
    }
  );

  const customTeacher = result?.data?.searchTeachers.items?.filter(
    (teacher) => teacher.teacherSubjectCategory
      ?.some((subject) => subject.split(">")[2] !== undefined && subject.split(">")[2] !== "")
  );

  return customTeacher;
}

export const fetchGetTeacher = async (_teacherIdx: string) => {
  const result = await graphqlFetcher<{ getTeacher: TGetTeacher }>(
    getTeacher,
    { id: `tec${_teacherIdx}` }
  );

  return result.data?.getTeacher;
}

export const fetchSearchTeacher = async (_teacherIdx: string) => {
  const result = await graphqlFetcher<{ searchTeachers: TSearchTeachers }>(
    searchTeachers,
    {
      filter: {
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
        teacherIdx: { eq: _teacherIdx },
      },
    }
  );

  return result?.data?.searchTeachers;
}

// 통합 API (선생님 프로필)
export const fetchTeacherDetail = async (_memberIdx: string): Promise<TTeacherDetail | undefined> => {
  const api = new ApiClient();
  const { data } = await api.post<APIReturnType<TTeacherDetail>>("/migration/teacher/detail", { "MemberIdx": _memberIdx });

  return data.data;
}

// 선생님 학원강의 리스트 - API 주소 확인 필요
export const fetchAcademyLecture = async (_teacherName: string): Promise<TAcademyLecture[]> => {
  const api = new ApiClient({
    baseUrl: `${window.location.origin}/admin/api`,
    contentType: "application/json",
  });

  const response = await api.get<AxiosResponse<TAcademyLecture[]>>(`/teacher?teacherName=${_teacherName}`);

  return response.data.data;
}