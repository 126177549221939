"use client";

import styles from "./teacherBanner.module.scss";
import commonStyle from "./main.module.scss";
import StrictImage from "@/components/image/StrictImage";
import Link from "next/link";
import { useListTeacher } from "@/hook/useTeacher";
import Image from "next/image";

export default function TeacherBanner() {
  const { data: teacherList } = useListTeacher();

  const teacher = teacherList
    ?.sort((a, b) => {
      if (a.sortValue === b.sortValue) {
        return a.displayTeacherName.localeCompare(b.displayTeacherName);
      }
      return a.sortValue - b.sortValue;
    })
    .filter((item) => Number(item.sortValue) !== 99999)
    .slice(0, 8);
    
  return (
    <>
      {teacherList && teacherList?.length > 0 && (
        <div className={styles.teacherBanner}>
          <div className="inner">
            <div className={commonStyle.secTitle}>
              <p>
                해커스 감정평가사 <b>최정상급 스타교수진</b>
              </p>
              <span>
                <Link href={"/teacher"}>자세히 보기</Link>
              </span>
            </div>
            <ul className={styles.teacherWrap}>
              {/* 소개등록여부 X - item.sortValue === 99999 */}
              {teacher &&
                teacher.map((item) => {
                  
                  const teacherSubject = Array.isArray(item.teacherSubjectCategory) &&
                  item.teacherSubjectCategory
                    ?.map((subject: any) => {
                      return subject.split(">")[3] != "undefined" ? subject.split(">")[3] : "";
                    })
                    .filter((subject: any) => subject != "")
                    .join(", ");
                    
                  return (
                    <li className={styles.teacherItem} key={item.teacherIdx}>
                      <Link
                        href={{ pathname: `/teacher/${item.teacherIdx}` }}
                        className={styles.slideItem}
                      >
                        {item.teacherListMainImage && (
                          <StrictImage
                            src={item.teacherListMainImage}
                            width={214}
                            height={273}
                            className={styles.teacherImg}
                            alt={item.displayTeacherName}
                          />
                        )}
                        <p className={styles.subject}>{teacherSubject}</p>
                        <p
                          className={styles.teacherIntroduce}
                          dangerouslySetInnerHTML={{ __html: item.teacherIntroduce }}
                        />
                        <p className={styles.teacherName}>
                          <span>{item.displayTeacherName}</span>
                          <br />
                          교수님
                        </p>
                        <p
                          className={styles.detail}
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </Link>
                    </li>
                  );
                })}
              {teacher &&
                teacher.length < 8 &&
                [...Array(8 - teacher.length)].map((_, idx) => (
                  <li key={idx} className={`${styles.teacherItem} ${styles.nonTeacher}`}>
                    <Image
                      src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/main/teacher_comming_v2.png`}
                      alt="comming soon"
                      width={248}
                      height={375}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
