"use client";

import React, { useState } from 'react';
import Link from "next/link";
import { useEffect, useRef } from "react";
import Slider, { LazyLoadTypes, Settings } from "react-slick";
import styles from './main.module.scss';
import { TBannerItem } from "@/interface/banner";
import StrictImage from "../image/StrictImage";

interface Props {
  bannerList: TBannerItem[] | undefined;
};

function BigBanner({ bannerList }: Props) {
  const [currentIdx, setCurrentIdx] = useState(1);

  const bannerSettings: Settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    lazyLoad: "ondemand" as LazyLoadTypes,
    speed: 800,
    initialSlide: 0,
    arrows: true,
    dots: true,
    draggable: false,
    beforeChange: (currentSlide, nextSlide) => setCurrentIdx(nextSlide + 1),
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<Slider>(null);

  // dot 내부 텍스트 설정 및 mouseover 이벤트 설정
  useEffect(() => {
    const dots = wrapperRef?.current?.querySelector(".slick-dots");
    if (dots && bannerList) {
      wrapperRef?.current?.querySelector(".slick-dots")?.querySelectorAll("li button").forEach((dot, idx) => {
        dot.innerHTML = bannerList[idx].title;
        dot.addEventListener("mouseover", () => bannerRef.current?.slickGoTo(idx));
      });
    }
  }, [bannerList]);

  return (
    <div ref={wrapperRef} className={styles.bigBanner}>
      <Slider {...bannerSettings} ref={bannerRef}>
        {
          bannerList?.map(data => (
            <div key={data.title} className={`pos_r ${styles.slideWrap}`}>
              <Link href={data.linkURL} target={data.bannerTargetCode} style={{ backgroundColor: `${data.backgroundColor ? data.backgroundColor : "#fff"}`, display: 'block' }} prefetch={false}>
                {(data.viewImageURL || data.viewIFrameURL) && <StrictImage priority alt={data.createdAt} src={data.viewImageURL ? data.viewImageURL : data.viewIFrameURL} width={2000} height={460} />}
              </Link>
            </div>
          ))
        }
      </Slider>
      {bannerList?.length === 1 &&
        <ul className="slick-dots" style={{ display: 'block', bottom: '-1px' }}>
          <li className="slick-active"><button>{bannerList[0].title}</button></li>
        </ul>
      }
      <p className={styles.badge}><b>{currentIdx}</b> &#47; {bannerList?.length}</p>
    </div>
  );
}

export default BigBanner;