"use client";

import React, { useCallback, useRef } from "react";
import Slider, { LazyLoadTypes, Settings } from "react-slick";
import styles from "./lectureReview.module.scss";
import { useRouter } from "next/navigation";
import StrictImage from "../image/StrictImage";
import { TBannerItem } from "@/interface/banner";
import { TSearchBoardsItem } from "@/interface/common";

interface Props {
  bannerList: TSearchBoardsItem[];
}

function LectureReviewBanner({ bannerList }: Props) {
  const router = useRouter();
  const slickRef = useRef<any>();
  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);

  const bannerSettings: Settings = {
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 4000,
    lazyLoad: "ondemand" as LazyLoadTypes,
    speed: 800,
    initialSlide: 0,
    arrows: false,
    dots: false,
    draggable: false,
    centerMode: true,
    infinite: bannerList.length > 5 ? true : false,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div className={styles.passReviewBanner}>
      <Slider {...bannerSettings} ref={slickRef}>
        {bannerList?.map((data) => (
          <div key={data?.id} className={styles.item}>
            <div className={styles.contentBox} dangerouslySetInnerHTML={{ __html: data.content }}></div>
            <p className={styles.userBox}>{data.title}</p>
          </div>
        ))}
      </Slider>
      <div className={styles.slideArrow}>
        <button type="button" className={styles.prev} onClick={previous}>
          이전
        </button>
        <button type="button" className={styles.next} onClick={next}>
          다음
        </button>
        <button type="button" className={styles.plus} onClick={() => router.push("/review")}>
          수강후기
        </button>
      </div>
    </div>
  );
}

export default LectureReviewBanner;
