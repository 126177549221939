import { APIReturnType } from "@/interface/api";
import { Data, LectureKeyType, RowDaum, TLatestLecture, TLectureList, TSmartfinderQueryOptions } from "@/interface/lecture";
import ApiClient from "@/lib/ApiClient";
import ApiClientBill from "@/lib/ApiClientBill";
import { useQuery, useMutation, UseQueryOptions } from "react-query";
import { QueryKeys, graphqlFetcher } from "@/assets/ts/queryClient";
import { searchLectures } from "@/graphql/queries";
import { fetchCampusLectureDetail } from "./fetch/fetchLecture";

export interface LectureDetailType {
  productIdx?: any;
  mode?: string;
  productInfo?: any;
  memberIdx?: number;
}

export const LectureKeys = {
  all: ["lecture"] as const,
  list: (params?: LectureKeyType) =>
    [...LectureKeys.all, "list", params] as const,
  academyDetail: (params?: { lectureIdx: string[] }) =>
    [...LectureKeys.all, "detail", params] as const,
};

const fetchLectures = async (
  params?: LectureKeyType,
): Promise<APIReturnType<Data>> => {
  const api = new ApiClient();
  const { data } = await api.get<APIReturnType<Data>>("/migration/product/listBySmartFinder", {
    params: params,
  });

  return data;
};

export const useLectureLists = (
  params?: LectureKeyType,
  options?: TSmartfinderQueryOptions,
) => {
  return useQuery(
    LectureKeys.list(params),
    () => fetchLectures(params),
    options,
  );
};

const fetchLecture = async (params: LectureKeyType): Promise<APIReturnType> => {
  const api = new ApiClient();
  const { data } = await api.post(
    `/migration/product/listBySmartFinder`,
    params,
  );

  return data;
};

export const useLectureList = () => {
  return useMutation<APIReturnType, Error, { params: LectureKeyType }>(
    ({ params }) => fetchLecture(params),
  );
};

const fetchLectureDetail = async (
  params: LectureDetailType,
): Promise<APIReturnType<RowDaum[]>> => {
  const api = new ApiClient();
  const { data } = await api.post<APIReturnType<RowDaum[]>>(`/migration/product/detail`, params);

  return data || {};
};


export const useLectureDetail = (params: LectureDetailType) => {
  return useQuery(
    [QueryKeys.LECTURE_DETAIL, params],
    () => fetchLectureDetail(params),
    { enabled: !!params?.productIdx }
  );
};

export const useCampusLectureDetail = (
  params?: { lectureIdx: string[] },
  options?: UseQueryOptions<APIReturnType<any>>,
) => {
  return useQuery<APIReturnType<any>>(
    LectureKeys.academyDetail(params),
    () => fetchCampusLectureDetail(params, true),
    options,
  );
};

//수강신청

const fetchLectureSubmit = async (params: any): Promise<APIReturnType> => {
  const api = new ApiClientBill();
  const { data } = await api.post(`/render/product`, params);

  return data;
};

export const useLectureSubmit = () => {
  return useMutation<APIReturnType<RowDaum[]>, Error, any>((params) =>
    fetchLectureSubmit(params),
  );
};

const fetchLectureCart = async (
  params: LectureDetailType,
): Promise<APIReturnType> => {
  const api = new ApiClient();
  const { data } = await api.post(`/payment/carts`, params);

  return data;
};

export const useLectureCart = () => {
  return useMutation<
    APIReturnType<RowDaum[]>,
    Error,
    { params: LectureDetailType }
  >(({ params }) => fetchLectureCart(params));
};

// 최근수강 강의 목록
const fetchLatestLecture = async (
  MemberIdx: number,
): Promise<TLatestLecture[]> => {
  const api = new ApiClient();
  const { data } = await api.get<APIReturnType<TLatestLecture[]>>(
    "/memberClass/getMemberIngClassList",
    {
      params: {
        MemberIdx,
        ServiceId: process.env.NEXT_PUBLIC_SERVICE_ID,
      },
    },
  );

  return data?.data || [];
};

export const useLatestLecture = (_memberIdx: number) => {
  return useQuery(
    [QueryKeys.LATEST_LECTURES],
    () => fetchLatestLecture(_memberIdx),
    { enabled: !!_memberIdx },
  );
};

export const useSearchLectures = (_filter?: any) => {
  return useQuery([QueryKeys.LIST_LECTURES, _filter], () =>
    graphqlFetcher<{ searchLectures: TLectureList }>(searchLectures, {
      filter: {
        ..._filter,
        _deleted: { ne: true },
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID }
      },
      limit: 1999
    })
  );
}

export const getBookDelivPrice = (integratedData: any, productIdxs: string[]) => {
  const productBook = integratedData?.data?.product_contents_book_product
    ?.filter((product: any) => productIdxs?.includes(product.ContentsIdx))
    ?.map((item: any) => item.book_product_contents_book[0])

    const maxDeliveryPrice = productBook?.reduce((maxPrice: any, item: any) => {
      const currentPrice = Number(item.deliv_products.Price);
      return currentPrice > maxPrice ? currentPrice : maxPrice;
    }, 0);

  return maxDeliveryPrice;
}