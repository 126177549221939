"use client";

import styles from "@/components/main/main.module.scss";
import { useListBoard } from "@/hook/useCommon";
import Link from "next/link";
import { Button } from "@/components/button";
import CouponPayment from "./CouponPayment";
import { useSession } from "next-auth/react";

export default function BottomSection() {
  const { status } = useSession();
  const { data: noticeList } = useListBoard(
    0,
    6, // limit
    "Notice" // typeCode
  );

  const { data: examCaseList } = useListBoard(
    0,
    6, // limit
    "ExamCase" // typeCode
  );

  const inquiryClick = () => {
    if (status === "authenticated") {
      window.open(`https://${process.env.NEXT_PUBLIC_ENV || ""}myclass.hackers.com/myinfo/counsel`);
    } else {
      window.open(
        `https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/login?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.href)}`
      );
    }
  };

  return (
    <div
      className={`${styles.sectionWrap} ${styles.sectionBottom}`}
      style={{ backgroundColor: "#EDEDED" }}
    >
      <div className={`inner ${styles.sectionBox}`}>
        <div className={styles.bottomSection}>
          <div className={styles.secTitle}>
            <p>
              <b>공지사항</b>
            </p>
            <Link
              href={{ pathname: "/cs/support" }}
              className={styles.titleAddBtn}
            />
          </div>
          <ul className={`${styles.bottomList} ${styles.linkWrap}`}>
            {noticeList?.items?.map((item, idx) => (
              <li key={item.id}>
                <Link href={`/cs/support/${item.id}`}>
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.bottomSection}>
          <div className={styles.secTitle}>
            <p>
              <b>기출문제</b>
            </p>
            <Link
              href={{ pathname: "/free_lecture/exam_case" }}
              className={styles.titleAddBtn}
            />
          </div>
          <ul className={`${styles.bottomList} ${styles.linkWrap}`}>
            {examCaseList?.items?.map((item, idx) => (
              <li key={item.id}>
                <Link href={`/free_lecture/exam_case/${item.id}`}>
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.bottomSection}>
          <div className={styles.secTitle}>
            <p>
              <b>고객센터</b>
            </p>
            <Link
              href={{ pathname: "/cs/faq" }}
              className={styles.titleAddBtn}
            />
          </div>
          <div className={`${styles.bottomList} ${styles.inquiryWrap}`}>
            <p className={styles.inquiryNum}>1588-2332</p>
            <p className={styles.inquiryInfo}>월~금 09~21시 / 토요일 09~18시</p>
          </div>
          <div className={`${styles.bottomList} ${styles.csWrap}`}>
            {/* 쿠폰코드 입력 */}
            <CouponPayment />
            <div className={styles.csBtmWrap}>
              <Link href={{ pathname: "/cs/faq" }}>자주 묻는 질문</Link>
              <Button width={100} height={35} onClick={inquiryClick}>
                1:1 문의하기
              </Button>
              <Link href={{ pathname: "/cs/guide/pcguide" }}>PC 수강안내</Link>
              <Link href={{ pathname: "/cs/program" }}>프로그램 다운</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
