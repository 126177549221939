'use client'

import React, { useState } from 'react';
import styles from './main.module.scss';
import Text from "../input/Text";
import { useSession } from "next-auth/react";
import { LoginCk } from "@/hook/useLoginCk";
import { useRouter } from "next/navigation";
import { Button } from "@/components/button";
import { usePaymentCoupon } from '@/hook/useFetchIntegration';

function CouponPayment() {
  const router = useRouter();
  const { status } = useSession();
  const { couponPayment } = usePaymentCoupon();
  const [input, setInput] = useState('');

  const handlePaymentCoupon = async () => {
    if (status === "unauthenticated") {
      LoginCk(router, status, "", window.location.href);
      return false;
    }

    if (!input) {
      alert("쿠폰번호를 입력해 주세요.");
      return false;
    }

    if (await couponPayment([input])) {
      alert("쿠폰이 지급되었습니다.");
      setInput("");
    } else {
      alert("쿠폰번호가 올바르지 않거나,\n이미 등록된 쿠폰번호입니다.");
    }
  };

  return (
    <div className={styles.couponWrap}>
      <p className={styles.couponTitle}>쿠폰&#38;수강권 등록</p>
      <div className={styles.couponInfo}>
        <Button width={20} height={20} className={styles.couponInfoBtn}>?</Button>

        <div className={styles.description}>
          <b>쿠폰이란?</b>
          <ul>
            <li>해커스 감정평가사 동영상 강의에서 진행하는 비 정기적인 이벤트참여로 수강할인 쿠폰을 받으실 수 있습니다.</li>
            <li>동영상강의 사이트 우측의 바로가기 버튼을 눌러 쿠폰을 등록한 후, 명시된 금액 또는 할인율만큼 낮아진 금액으로 수강신청이 가능합니다.</li>
          </ul>

          <b>쿠폰 사용 안내</b>
          <ul>
            <li>화면 우측의’등록’이나 [나의정보]-[나의쿠폰]에서 대소문자를 구분하여 알파벳 및 숫자를 입력하시면 쿠폰이 등록됩니다. 쿠폰마다 정해진 유효기간이 있으므로, 반드시 명시된 유효기간 내에만 사용해주시기 바랍니다.</li>
            <li>포인트와 쿠폰은 하나의 강의에 중복할인 적용이 불가합니다.</li>
            <li>한번 사용한 쿠폰은 재생성되지 않으므로, 수강신청 후 취소/환불/반품하는 경우 그 쿠폰은 재사용이 불가능합니다.</li>
            <li>해커스 감정평가사 미 동영상 강의의 쿠폰은 현금화하여 환불되지 않습니다.</li>
            <li>
              해커스 감정평가사 동영상 강의의 쿠폰은 매매,교환,증여,담보제공 등의 방법으로 제 3자에게 양도할 수 없으며, 유·무상 등의 방법으로 제3자와 건래한 경우 해당 쿠폰은 사용이 불가능합니다. 당사는 쿠폰 거래로 인해 발생한 모든 손해에 대하여 책임지지 않습니다.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.couponInput}>
        <Text height={37} value={input} onChange={(e) => setInput(e.target.value)} />
        <button type="button" onClick={handlePaymentCoupon}>등록</button>
      </div>
    </div>
  );
}

export default CouponPayment;