"use client";

import { TBannerItem } from "@/interface/banner";
import styles from "./freeEvent.module.scss";
import commonStyle from "./main.module.scss";
import BannerItem from "./BannerItem";
import { _zeroFill } from "@/assets/ts/Utils";
import Link from "next/link";
import { useState } from "react";
import Slider, { Settings, LazyLoadTypes } from "react-slick";
import StrictImage from "../image/StrictImage";

interface Props {
  bannerList: TBannerItem[];
  bottomBannerList: TBannerItem[];
}
export default function FreeEvent({ bannerList, bottomBannerList }: Props) {
  const [currentIdx, setCurrentIdx] = useState(1);

  const bannerSettings: Settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    lazyLoad: "ondemand" as LazyLoadTypes,
    speed: 800,
    initialSlide: 0,
    arrows: true,
    dots: false,
    draggable: false,
    beforeChange: (currentSlide, nextSlide) => setCurrentIdx(nextSlide + 1),
  };
  return (
    <div className={styles.freeEvent}>
      <div className="inner">
        <div className={commonStyle.secTitle}>
          <p>
            인기 최고 <b>무료 이벤트</b>
          </p>
        </div>
        <ul className={styles.freeBanner}>
          {bannerList?.map((banner, idx) =>
            idx < 3 ? <BannerItem banner={banner} key={idx} height={412} /> : null
          )}
        </ul>

        <div className={styles.freeBottomBanner}>
          <div className={styles.slideWrap}>
            <Slider {...bannerSettings}>
              {bottomBannerList?.map((data) => (
                <div key={data.title} className="pos_r">
                  <Link href={data.linkURL} target={data.bannerTargetCode}>
                    <StrictImage
                      priority
                      alt={data.createdAt}
                      src={data.viewImageURL || data.viewIFrameURL}
                      width={1050}
                      height={156}
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>

          <p className={styles.badge}>
            <b>{_zeroFill(`${currentIdx}`, 2)}</b> &#47;{" "}
            {_zeroFill(`${bottomBannerList?.length}`, 2)}
          </p>
        </div>
      </div>
    </div>
  );
}
