import { usePathname, useRouter } from "next/navigation";
import { fetchPaymentProductInfo, fetchPayProduct } from "./fetch/fetchLecture";
import useUserStore from "@/store/user";
import { useLectureCart } from "./useLecture";
import { fetchPaymentRecordCount } from "./fetch/fetchEvent";
import { QueryKeys } from "@/assets/ts/queryClient";
import { useQuery } from "react-query";

export interface TPayProductParams {
  productIdx: number[];
  parentIdx: number[] | number;
  eventCode?: string;
}

type TCartProductInfo = Array<{
  productIdx: number;
  parentProductIdx: number;
}>;

export default function useProduct() {
  const { mutate: mutateLectureCart } = useLectureCart();
  const router = useRouter();
  const pathname = usePathname();
  const user = useUserStore((state) => state.user) || {
    id: "",
    idx: "",
    name: "",
  };

  // 수강신청 이동
  const payProduct = async (params: TPayProductParams) => {
    if (!user.idx) {
      router.push(`/auth/checkAuth?redirect=${pathname}`);
      return;
    }

    const res = await fetchPayProduct({
      mode: "order",
      ...params,
    });

    if (res.data) {
      router.push(res.data);
    }
  };

  // 장바구니
  const cartProduct = (
    productInfo: TCartProductInfo,
    type?: "book" | "lect"
  ) => {
    if (!user.idx) {
      router.push(`/auth/checkAuth?redirect=${pathname}`);
      return;
    }

    mutateLectureCart(
      {
        params: {
          memberIdx: user?.idx,
          productInfo,
        },
      },
      {
        onSuccess: (res: any) => {
          if (res?.data?.cartIdxList.length == 0) {
            if (
              confirm(
                "이미 장바구니에 상품이 담겨져 있습니다. 장바구니로 이동하시겠습니까?"
              )
            ) {
              window.location.href = `https://${
                process.env.NEXT_PUBLIC_ENV || ""
              }bill.hackers.com/cart`;
            }
          } else {
            if (
              confirm(
                `${
                  type === "lect" ? "강의" : "교재"
                }를 장바구니에 담았습니다. 지금 확인하시겠습니까?`
              )
            ) {
              window.location.href = `https://${
                process.env.NEXT_PUBLIC_ENV || ""
              }bill.hackers.com/cart`;
            }
          }
        },
        onError: (error: unknown) => console.log(error),
      }
    );
  };

  // 구매 여부 체크
  const checkIsPaid = async (productIdxList: number[]) => {
    if (!user.idx) {
      return false;
    }

    const { data: paymentInfo } = await fetchPaymentProductInfo(
      {
        memberIdx: String(user.idx),
        productIdxList,
        isPaid: 0,
        isPayment: 1,
      },
      true
    );

    return paymentInfo?.some(info => info.IsPaid) ?? false;
  };

  // 당일 결제 정보 횟수 조회
  const useGetPaymentRecordCount = (
    issueBeginDate: string,
    issueEndDate: string,
    productIdx: number,
    isNotFree: number
  ) => {
    return useQuery(
      [
        QueryKeys.GET_PAYMENT_COUNT_DATA,
        issueBeginDate,
        issueEndDate,
        productIdx,
        isNotFree,
      ],
      () =>
        fetchPaymentRecordCount({
          serviceIdList: [process.env.NEXT_PUBLIC_SERVICE_ID || ""],
          issueBeginDate,
          issueEndDate,
          productIdx,
          isNotFree,
        })
    );
  };

  return {
    payProduct,
    cartProduct,
    checkIsPaid,
    useGetPaymentRecordCount,
  };
}
