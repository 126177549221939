import ApiClient from "@/lib/ApiClient";
import axios, { AxiosResponse } from "axios";
import { LectureDetailType } from "../useLecture";
import { APIReturnType } from "@/interface/api";
import { TPaymentProductInfo } from "@/interface/lecture";

export const fetchPayProduct = async (data: any): Promise<any> => {
  let nowDomain = process.env.NEXT_PUBLIC_DOMAIN;
  const nowHost = window.location.host;

  if (nowHost.startsWith("pr")) {
    nowDomain = window.location.origin;
  }

  const api = new ApiClient({
    baseUrl: `${nowDomain}/admin/api`,
    contentType: "application/json",
  });

  const response = await api.post<AxiosResponse<any>>(`/pay-product`, data);

  return response.data;
};

export const fetchPaymentProductInfo = async (
  params: {
    memberIdx: string;
    productIdxList: number[];
    isPaid?: number;
    isPayment?: number;
  },
  isClient = false,
) => {
  const api = new ApiClient();

  const res = await api.get<APIReturnType<TPaymentProductInfo[]>>(
    `${
      isClient ? "" : process.env.NEXT_PUBLIC_API_URL
    }/payment/order/products/info`,
    { params },
  );

  return res.data;
};

export const fetchLectureDetail = async (
  params: LectureDetailType,
): Promise<APIReturnType> => {
  const api = new ApiClient();
  const { data } = await api.post(`/migration/product/detail`, params);

  return data;
};

// 학원 강의상세
export const fetchCampusLectureDetail = async (
  params?: {
    lectureIdx: string | string[];
  },
  isClient?: boolean,
): Promise<APIReturnType<any>> => {
  const api = new ApiClient({
    contentType: "application/json; charset=utf-8",
    baseUrl: "/v1",
    serviceid: "3144"
  });

  const { data } = await api.get(
    `${isClient ? "" : process.env.NEXT_PUBLIC_API_URL}/academy/product/detail`,
    {
      params: params,
    },
  );

  return data;
};
