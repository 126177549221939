"use client";

import React from "react";
import styles from "./lectureReview.module.scss";
import commonStyle from "./main.module.scss";
import LectureReviewBanner from "./LectureReviewBanner";
import { useListBoard } from "@/hook/useCommon";
import { TBannerItem } from "@/interface/banner";

interface Props {
  bannerList: TBannerItem[];
}

export default function LectureReview() {
  const { data, isSuccess, refetch } = useListBoard(0, 100, "MainReview", {}, { cacheTime: 0, staleTime: 0 });

  return (
    <div className={styles.passReview}>
      <div className="inner">
        <div className={`${commonStyle.secTitle} ${commonStyle.whiteTxt}`}>
          <p>
            해커스 교육그룹 수강생들의 <b>생생한 수강후기</b>
          </p>
        </div>
      </div>

      <LectureReviewBanner bannerList={data?.items || []} />
    </div>
  );
}
