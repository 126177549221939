import { graphqlFetcher } from "@/assets/ts/queryClient";

import { getEventLogsCount, searchEventCardinalBenefits, searchEventLogs, searchGetEvents } from "@/graphql/customQueries";
import { createEventLog, updateEventLog } from "@/graphql/mutations";

import { EventLog, SearchableEventLogFilterInput } from "@/API";
import { TCreateEventLogInput, TEventCardinalBenefits, TPaymentRecordCount, TSearchEventLog, TSearchEvents, TUpdateEventLogInput } from "@/interface/event";
import { APIReturnType } from "@/interface/api";
import ApiClient from "@/lib/ApiClient";

// 이벤트 정보 가져오기
export const fetchSearchEvent = async (eventCode: string, filter?: any) => {
  try {
    const result = await graphqlFetcher<{ searchEvents: { items: TSearchEvents[] } }>(
      searchGetEvents,
      {
        filter: {
          sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
          eventCode: { wildcard: `*${eventCode}*` },
          ...filter,
        }
      }
    );

    return result.data?.searchEvents.items;
  } catch (error) {
    console.log("*** fetch Error ***");
    console.log(error);

    return [
      {
        id: 9999,
        mainCategoryClassCode: "",
        subCategoryClassCode: "",
        eventCode: "error",
        eventName: "",
        eventURL: "",
        ogImage: "",
        ogKeyword: "",
        ogDescription: "",
        quickBanner: "",
        topBanner: "",
        topLineBanner: "",
        footerBanner: "",
        footerLineBanner: "",
        isFooterFix: "",
        eventVideos: [],
        eventBenefits: [],
        scheduleImage: "",
        lectureLists: [],
        freepassLists: [
          { categoryName: "error", categoryItems: [{ itemName: "error", items: 9999 }] }
        ],
        isUseTimer: false,
        isTimerFixed: false,
        eventCardinals: [
          { cardinalBeginDate: "", cardinalEndDate: "", cardinalNumber: 9999 }
        ],
        eventInstructions: [
          {
            InstructionNumber: 9999,
            InstructionTitle: String(error),
            InstructionContent: String(error)
          }
        ]
      }
    ] as unknown as TSearchEvents[];
  }
}

// 이벤트 로그 가져오기
export const fetchSearchEventLog = async (eventCode: string, filter: SearchableEventLogFilterInput, _sort?: any) => {
  let result = await graphqlFetcher<{ searchEventLogs: { items: TSearchEventLog[], total: number } }>(
    searchEventLogs,
    {
      filter: {
        ...filter,
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
        eventCode: { eq: eventCode },
      },
      sort: [_sort ? _sort : { field: "createdAt", direction: "desc" }],
    }
  );

  if (result.data?.searchEventLogs) {
    result.data.searchEventLogs.items = result.data.searchEventLogs.items.map(i => {
      return {
        ...i,
        value: JSON.parse(i.value)
      }
    });
  }

  return result.data?.searchEventLogs;
}

// 이벤트 참여 이력 생성
export const fetchCreateEventLog = async (input: TCreateEventLogInput) => {
  const result = await graphqlFetcher<{ createEventLog: EventLog }>(
    createEventLog,
    {
      input: {
        ...input,
        sitesID: process.env.NEXT_PUBLIC_SITEID,
      }
    }
  );

  return result.data?.createEventLog;
}

// 이벤트 참여 이력 업데이트
export const fetchUpdateEventLog = async (input: TUpdateEventLogInput) => {
  const result = await graphqlFetcher<{ updateEventLog: EventLog }>(
    updateEventLog,
    {
      input: {
        ...input,
      }
    }
  );

  return result.data?.updateEventLog;
}

// 당일 결제한 회원들 조회
export const fetchPaymentRecordCount = async (params: {
  serviceIdList: string[],
  issueBeginDate: string,
  issueEndDate: string,
  // deviceType: string,
  productIdx: number,
  isNotFree: number,
}, isClient = true) => {
  const api = new ApiClient();
  const res = await api.get<APIReturnType<TPaymentRecordCount>>(
    `${isClient ? "" : process.env.NEXT_PUBLIC_API_URL}/statistics/ordering/ordering_product`,
    { params },
  );
  return res.data.data;
}

// 회원 정보 조회
export const fetchMemberInfo = async (params: { memberId: string, regDate: string }) => {
  const api = new ApiClient({
    baseUrl: "/admin/api",
    contentType: "application/json; charset=utf-8",
  });

  const res = await api.get<APIReturnType<boolean>>("/event/checkNewMember", { params });

  return res.data.data;
}

// 이벤트 참여 이력 count 조회
export const fetchEventLogsCount = async (
  eventCode: string,
  filter: SearchableEventLogFilterInput
) => {
  const result = await graphqlFetcher<{ searchEventLogs: { total: number } }>(
    getEventLogsCount,
    {
      filter: {
        ...filter,
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
        eventCode: { eq: eventCode },
      },
    }
  );

  return result.data?.searchEventLogs?.total;
};

/**
 * ### 기수별 상품혜택 조회
 */
export const fetchCardinalBenefits = async (eventCode: string) => {
  const result = await graphqlFetcher<{ searchEventCardinalBenefits: { items: TEventCardinalBenefits[] } }>(
    searchEventCardinalBenefits,
    {
      filter: {
        _deleted: { ne: true },
        siteCode: { eq: process.env.NEXT_PUBLIC_SERVICE_ID },
        eventCode: { eq: eventCode }
      }
    }
  );

  return result.data?.searchEventCardinalBenefits.items[0];
}