import(/* webpackMode: "eager" */ "/codebuild/output/src3737100692/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/image/StrictImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/BigBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/BottomSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/FreeEvent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/LectureReview.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3737100692/src/src/components/main/main.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/RecommendLecture.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/SpecificUserBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/TeacherBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/main/TopBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3737100692/src/src/components/sidebar/QuickBanner.tsx");
