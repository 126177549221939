import { ExceedsPriceParams } from "@/interface/academy";
import { useQuery, UseQueryOptions } from "react-query";
import { fetchExceedsPrice } from "./fetch/fetchAcademy";

export const academyKeys = {
  all: ["academy"] as const,
  exceedsPrice: (params: ExceedsPriceParams) =>
    [...academyKeys.all, "exceedsPrice", params.memberIdx] as const,
};

// 학원 기준금액 이상 결제 여부 확인
export const useExceedsPrice = (
  params: ExceedsPriceParams,
) => {
  return useQuery<any>(
    academyKeys.exceedsPrice(params),
    () => fetchExceedsPrice(params),
    
  );
};
