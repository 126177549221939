import { graphqlFetcher } from "@/assets/ts/queryClient";

import { searchPassersLists } from "@/graphql/queries";

import { PassersList } from "@/API";

// 합격자 명단 가져오기
export const fetchPassersList = async () => {
  try {
    const result = await graphqlFetcher<{
      searchPassersLists: { items: PassersList[] };
    }>(searchPassersLists, {
      filter: {
        sitesID: { eq: process.env.NEXT_PUBLIC_SITEID },
      },
    });

    return result.data?.searchPassersLists.items;
  } catch (error) {
    console.log("*** fetch Error ***");
    console.log(error);

    return [];
  }
};
