import { ExceedsPriceParams } from "@/interface/academy";
import { APIReturnType } from "@/interface/api";
import ApiClient from "@/lib/ApiClient";

// 기준금액 이상 결제 여부 확인
export const fetchExceedsPrice = async (
  params: ExceedsPriceParams
): Promise<APIReturnType<any>> => {
  if (!params.memberIdx) {
    return {
      data: { exceedBasePrice: false },
      code: "500",
      message: "memberIdx is required",
    };
  }

  const api = new ApiClient({
    contentType: "application/json; charset=utf-8",
    baseUrl: "/api",
    serviceid: "3144", // 학원
  });

  const response = await api.get(`/academy/isPayment`, {
    params,
  });

  return response.data;
};
