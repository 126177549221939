"use client";

import type { QuickMenuItem } from "@/interface/common";
import React, { useState, useRef, useEffect } from "react";
import { useSession } from "next-auth/react";
import styles from "./quickBanner.module.scss";
import Image from "next/image";
import Link from "next/link";
import { scrollLocation } from "@/assets/ts/Utils";
import Consulting from "../main/Consulting";

interface Props {
  data: QuickMenuItem[];
}

function QuickBanner({ data }: Props) {
  // const { data, isSuccess } = useQuickMenu({});
  const [isFixed, setIsFixed] = useState(false);
  const { status } = useSession();
  const [isConsulting, setIsConsulting] = useState(false); 

  const handleQuickFixed = () => {
    const quick = document.querySelector<HTMLElement>(`.${styles.quickWrap}`);

    if (quick) {
      if (isFixed) {
        setIsFixed(false);
        quick.style.top = 220 + 'px';
        return;
      }

      const location = window.scrollY || document.documentElement.scrollTop;
      setIsFixed(true);
      quick.style.top = (location + 220) + 'px';
    }
  }

  return (
    <>
      <div className={`${styles.quickWrap}  ${isFixed ? styles.fixed : ''}`}>
        <div className={styles.quickInner}>
          <Image src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/main/quick_menu.png`} alt="quick menu" width={140} height={54} />
          {data.length > 0 &&
            <div className={styles.navigation}>
              <ul className={styles.quickLnb}>
                {data.map((menu) => (
                  <li key={menu.id}>
                    <Link href={menu.linkURL} target={menu.target}>
                      {menu.menuName}
                    </Link>
                  </li>
                ))}
                <li>
                  <button type="button" onClick={
                    () => {
                      if (status === "unauthenticated") {
                        alert("로그인 후 이용가능합니다.");
                        window.location.href = `https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/login?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.href)}`;
                        return;
                      }
                      window.location.href = `https://${process.env.NEXT_PUBLIC_ENV || ""}myclass.hackers.com/myinfo/coupon?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`;
                    }
                  }>쿠폰/수강권등록</button>
                </li>
              </ul>
            </div>
          }
          <ul className={styles.goTool}>
            <li>
              <button onClick={() => scrollLocation("body")} type="button">
                TOP
                <i className={`${styles.quickMenuIcon} ${styles.icon01}`}></i>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                  });
                }}
                type="button"
              >
                DOWN
                <i className={`${styles.quickMenuIcon} ${styles.icon02}`}></i>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`${styles.btnFixed} `}
                onClick={handleQuickFixed}
              >
                LOCK
                <i className={`${styles.quickMenuIcon} ${isFixed ? styles.icon03 : styles.icon04}`}></i>
              </button>
            </li>
          </ul>
        </div>
        <button type="button" className={styles.consultingBanner} onClick={() => setIsConsulting(true)}>
          <Image src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/event/consulting/consulting_banner.png`} alt="상담신청 시 수강료 파격지원" width={140} height={135} />
        </button>
      </div>
      {/* 컨설팅 팝업 */}
      {isConsulting && <Consulting setIsConsulting={setIsConsulting} />}
    </>
  );
}

export default QuickBanner;
