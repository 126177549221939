import React from 'react';
import styles from './main.module.scss';
import Link from "next/link";
import { TBannerItem } from "@/interface/banner";
import StrictImage from "../image/StrictImage";


interface Props {
  banner: TBannerItem;
  height?: number;
};

function BannerItem({ banner, height = 406 }: Props) {
  return (
    <li className={styles.item}>
      <Link href={banner?.linkURL ? banner?.linkURL : '/'} target="_blank">
        <StrictImage priority alt={banner?.title} src={banner?.viewImageURL || banner?.viewIFrameURL} width={336} height={height} />
      </Link>
    </li>
  );
}

export default BannerItem;