import React, { ChangeEvent, ReactNode } from "react";
import styles from "./inputText.module.scss";

export type InputValue = string | number | ReadonlyArray<string>;
export type InputChangeEvent = ChangeEvent<HTMLInputElement>;

interface InputProps {
  className?: string;
  width?: number;
  height?: number;
  theme?: "default" | "gray";
  variant?: "base" | "borderNone";
  value?: InputValue;
  onChange?: (ev: InputChangeEvent) => void;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  readOnly?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
}

/**
 * Input Text
 *
 * @property {"default" | "gray" } theme 테마 (default: default)
 * @property {"borderNone" } variant 형태
 * @property {number | string} width 가로
 * @property {number} height 세로
 * @property {string} placeholder
 * @property {value} value 값 state
 * @property {onChange} value 이벤트 핸들러 함수
 * @property {string} name input name
 * @property {boolean} readOnly
 * @example
 * <Text width={100} height={50} theme="gray" className={styles.leftInput} value={input} onChange={handleInput}/>
 *
 */
function Text({
  theme = "default",
  variant,
  width,
  height,
  className,
  value,
  onChange,
  placeholder,
  name,
  readOnly = false,
  ref,
  ...rest
}: InputProps) {
  return (
    <input
      type="text"
      style={{
        width,
        height,
        lineHeight: `${
          height && className !== styles["border-none"]
            ? height - 2 + "px"
            : "inherit"
        }`,
      }}
      className={`${styles[theme]} ${variant ? styles[variant] : ""} ${
        styles.base
      } ${className}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      readOnly={readOnly}
      ref={ref && ref}
      {...rest}
    />
  );
}

export default Text;
