"use client";

import Link from "next/link";
import styles from "./main.module.scss";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";
import useProduct from "@/hook/useProduct";
import { useSession } from "next-auth/react";
import useUserStore from "@/store/user";
import { useExceedsPrice } from "@/hook/useAcademy";
import { useEventUtils } from "@/hook/useEvent";

export default function SpecificUserBanner() {
  const { status } = useSession();
  const user = useUserStore((state) => state.user);
  const { checkIsPaid } = useProduct();
  const isLogin = user?.idx && status === "authenticated";

  const { data: academyData } = useExceedsPrice({
    memberIdx: user?.idx,
    basePrice: 100000, // 100,000원 이상 결제
  });

  const [isOpen, setIsOpen] = useState(true);
  const [isPaid, setIsPaid] = useState(false);

  const academyPaymentUser = useMemo(() => {
    return academyData?.data ? academyData?.data?.exceedBasePrice : true;
  }, [academyData?.data]);

  const { appointmentTime } = useEventUtils();

  let payLectureIdxArr = [0];
  if ((process.env.NEXT_PUBLIC_ENV || "") === "t") {
    // 개발 서버 확인 용
    payLectureIdxArr = [8577, 8491];
  } else {
    payLectureIdxArr = [10749, 10263, 10251, 10252, 10285, 10750, 10751, 10752, 11130, 11666, 11669, 11670];
  }

  const isAppointmentValid = appointmentTime("2024-08-31 23:59:59");

  useEffect(() => {
    (async () => {
      const result = await checkIsPaid(payLectureIdxArr);
      setIsPaid(result);
    })();
  }, [isLogin]);

  return (
    <>
      {isAppointmentValid && isLogin && isPaid && !academyPaymentUser && isOpen && (
        <div className={styles.specificUserBanner}>
          <Image
            src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/main/banner/quick_240809.png`}
            alt={"학원 2차 종합반 1년 수강권"}
            width={140}
            height={341}
          />
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            닫기
          </button>
          <Link href={"https://caac.hackers.com/lecture/261981"} target="_blank">
            학원 2차 종합반 1년 수강권
          </Link>
        </div>
      )}
    </>
  );
}
